import { useState, createContext } from "react";

interface Page {
  current: number;
  total: number;
  sorting: string;
}

const contextDefaultValues: any = {
  page: { current: 0, total: 0, sorting: "asc" },
  setPage: () => {},
};

const PageContext = createContext<any>(contextDefaultValues);

function PageProvider(props: any) {
  const [page, setPage] = useState<Page>({
    current: 0,
    total: 0,
    sorting: "asc",
  });

  return (
    <PageContext.Provider value={[page, setPage]}>
      {props.children}
    </PageContext.Provider>
  );
}

const SecondPageContext = createContext<any>(contextDefaultValues);

function SecondPageProvider(props: any) {
  const [page, setPage] = useState<Page>({
    current: 0,
    total: 0,
    sorting: "asc",
  });

  return (
    <SecondPageContext.Provider value={[page, setPage]}>
      {props.children}
    </SecondPageContext.Provider>
  );
}

const ThirdPageContext = createContext<any>(contextDefaultValues);

function ThirdPageProvider(props: any) {
  const [page, setPage] = useState<Page>({
    current: 0,
    total: 0,
    sorting: "asc",
  });

  return (
    <ThirdPageContext.Provider value={[page, setPage]}>
      {props.children}
    </ThirdPageContext.Provider>
  );
}
const FourthPageContext = createContext<any>(contextDefaultValues);

function FourthPageProvider(props: any) {
  const [page, setPage] = useState<Page>({
    current: 0,
    total: 0,
    sorting: "asc",
  });

  return (
    <FourthPageContext.Provider value={[page, setPage]}>
      {props.children}
    </FourthPageContext.Provider>
  );
}

export {
  PageContext,
  PageProvider,
  SecondPageContext,
  SecondPageProvider,
  ThirdPageContext,
  ThirdPageProvider,
  FourthPageProvider,
  FourthPageContext,
};
