import { useState, createContext, useEffect } from "react";

interface Authenticate {
  isAutharize: boolean;
  userData: Object;
}

const contextDefaultValues: Authenticate = {
  isAutharize: false,
  userData: {},
};

const AuthenticateContext = createContext<any>(contextDefaultValues);

function AuthenticateProvider(props: any) {
  const [Authenticate, setAuthenticate] =
    useState<Authenticate>(contextDefaultValues);

  useEffect(() => {
    const tempUser = localStorage.getItem("user");
    const user = tempUser && JSON.parse(tempUser);
    user && setAuthenticate({ isAutharize: true, userData: user });
  }, []);

  return (
    <AuthenticateContext.Provider value={[Authenticate, setAuthenticate]}>
      {props.children}
    </AuthenticateContext.Provider>
  );
}

export { AuthenticateContext, AuthenticateProvider };
