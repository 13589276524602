import { AuthenticateProvider } from "./Auth";
import { CourseAppFormProvider } from "./Form";
import LanguageState from "./Lang";
import {
  FourthPageProvider,
  PageProvider,
  SecondPageProvider,
  ThirdPageProvider,
} from "./Page";
import { VariableProvider } from "./Variable";

function Context({ children }: { children: any }) {
  return (
    <AuthenticateProvider>
      <VariableProvider>
        <LanguageState>
          <PageProvider>
            <SecondPageProvider>
              <ThirdPageProvider>
                <FourthPageProvider>
                  <CourseAppFormProvider>{children}</CourseAppFormProvider>
                </FourthPageProvider>
              </ThirdPageProvider>
            </SecondPageProvider>
          </PageProvider>
        </LanguageState>
      </VariableProvider>
    </AuthenticateProvider>
  );
}

export default Context;
