import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Context from "./context";
import reportWebVitals from "./reportWebVitals";
import "react-image-lightbox/style.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";

const container: any = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Context>
    <App />
  </Context>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
