import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { privateRoutes } from "./routes";
import { ScrollToTop } from "./utils";
import "./assets/css/index.css";
import "react-toastify/dist/ReactToastify.css";
import AppLoader from "./components/AppLoader";

const Login = lazy(() => import("./routes/auth/Login"));

function App() {
  return (
    <Suspense fallback={<AppLoader />}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />
          {privateRoutes.map((item: any, index: number) => (
            <Route
              key={index}
              path={`/${item.route}`}
              element={<item.component />}
            />
          ))}
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
