import { useState, createContext } from "react";

interface Variable {
  isStudentReportFilter: boolean;
  isSideBarActive: Boolean;
  activeType: String;
}

const contextDefaultValues: Variable = {
  isStudentReportFilter: false,
  isSideBarActive: false,
  activeType: "calendar",
};

const VariableContext = createContext<any>(contextDefaultValues);

function VariableProvider(props: any) {
  const [variable, setVariable] = useState<Variable>(contextDefaultValues);

  return (
    <VariableContext.Provider value={[variable, setVariable]}>
      {props.children}
    </VariableContext.Provider>
  );
}

export { VariableContext, VariableProvider };
