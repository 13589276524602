import { lazy } from "react";

const Dashboard = lazy(() => import("./dashboard"));
const Centers = lazy(() => import("./centers"));
const Banner = lazy(() => import("./home/Banner"));
const AddCenter = lazy(() => import("./centers/cpn/Add"));
const EditCenter = lazy(() => import("./centers/cpn/EditCenter"));
const Courses = lazy(() => import("./courses"));
const Teachers = lazy(() => import("./teachers"));
const Introduction = lazy(() => import("./home/EditBanner"));
const FormComplete = lazy(() => import("./registeration/cpn/FormComplete"));
const Person = lazy(() => import("./person"));
const Profile = lazy(() => import("./account/Profile"));
const ChangePassword = lazy(() => import("./account/ChangePassword"));
const CenterDetail = lazy(() => import("./centers/detail"));
const Schedule = lazy(() => import("./schedules"));
const AddSchedule = lazy(() => import("./schedules/Add"));
const EditSchedule = lazy(() => import("./schedules/Edit"));
const ScehduleDetail = lazy(() => import("./schedules/Detail"));
const CourseTitle = lazy(() => import("./courses/CoursesTitle"));

const ServerTimetable = lazy(
  () => import("./registeration/cpn/ServerTimetable")
);
const NewStudentRegisteration = lazy(
  () => import("./registeration/newStudent")
);
const OldStduentRegisteration = lazy(
  () => import("./registeration/oldStudent")
);
const ServerRegisteration = lazy(() => import("./registeration/server"));
const NewStudentForm = lazy(
  () => import("./registeration/newStudent/cpn/NewStudentForm")
);
const RegisterNewStudent = lazy(
  () => import("./registeration/newStudent/cpn/Register")
);
const RegisterOldStudent = lazy(
  () => import("./registeration/oldStudent/cpn/RegisterOld")
);
const OldStudentForm = lazy(
  () => import("./registeration/oldStudent/cpn/OldStudentForm")
);
const ServerForm = lazy(() => import("./registeration/server/ServerForm"));
const RegistrationDetail = lazy(
  () => import("./registeration/newStudent/cpn/Detail")
);
const OldStudentRegistrationDetail = lazy(
  () => import("./registeration/oldStudent/cpn/Detail")
);
const ServerRegistrationDetail = lazy(
  () => import("./registeration/server/Detail")
);
const NewStudentFormEdit = lazy(
  () => import("./registeration/newStudent/cpn/Edit")
);
const EditRegisterNewStudent = lazy(
  () => import("./registeration/newStudent/cpn/EditSecondPage")
);
const OldStudentFormEdit = lazy(
  () => import("./registeration/oldStudent/cpn/Edit")
);
const EditRegisterOldStudent = lazy(
  () => import("./registeration/oldStudent/cpn/EditSecond")
);
const EditServerForm = lazy(() => import("./registeration/server/Edit"));
const Reports = lazy(() => import("./reports"));
const ReportDetail = lazy(() => import("./reports/cpn/DetailReport"));
const ChildrenTennagers = lazy(() => import("./childrenTennage"));
const AddChildren = lazy(() => import("./childrenTennage/Add"));
const EditChildren = lazy(() => import("./childrenTennage/Edit"));
const LongCourseForm = lazy(
  () => import("./registeration/longCourse/LongCourseForm")
);
const SecondPage = lazy(() => import("./registeration/longCourse/SecondPage"));
const AssistantTeacher = lazy(
  () => import("./registeration/longCourse/AssistantTeacher")
);
const RegionalTeacher = lazy(
  () => import("./registeration/longCourse/RegionalTeacher")
);
const LongCourseFormRegistration = lazy(
  () => import("./registeration/longCourse")
);
const AddUser = lazy(() => import("./person/AddUser"));
const EditUser = lazy(() => import("./person/EditUser"));
const AddReports = lazy(() => import("./reports/cpn/Add"));
const EditReports = lazy(() => import("./reports/cpn/Edit"));
const EditSecondPage = lazy(
  () => import("./registeration/longCourse/EditSecondPage")
);
const EditLongForm = lazy(
  () => import("./registeration/longCourse/EditLongForm")
);
const LongFormDetail = lazy(() => import("./registeration/longCourse/Detail"));
const Referrals = lazy(() => import("./referral"));
const ScheduleByTeacher = lazy(() => import("./scheduleByTeacher"));
const AddTeachers = lazy(() => import("./scheduleByTeacher/cpn/Add"));
const AllStudentData = lazy(() => import("./allStudentData"));
const AddAllStudentData = lazy(() => import("./allStudentData/cpn/Add"));
const EditData = lazy(() => import("./allStudentData/cpn/Edit"));
const StudentDetail = lazy(() => import("./allStudentData/cpn/Detail"));
const ScheduleByCenter = lazy(() => import("./centers/ScheduleByCenter"));
const TeacherView = lazy(() => import("./scheduleByTeacher/views"));
const EditTeachers = lazy(() => import("./scheduleByTeacher/cpn/Edit"));

export const privateRoutes = [
  { route: "profile", component: Profile },
  { route: "create-user", component: AddUser },
  { route: "edit-user", component: EditUser },
  { route: "change-password", component: ChangePassword },
  { route: "dashboard", component: Dashboard },
  { route: "banner", component: Banner },
  { route: "edit-banner", component: Introduction },
  { route: "centers", component: Centers },
  { route: "children-and-tennagers", component: ChildrenTennagers },
  { route: "add-children-and-teenager-course", component: AddChildren },
  { route: "edit-children-and-teenager-course", component: EditChildren },
  { route: "report", component: Reports },
  { route: "add-report", component: AddReports },
  { route: "edit-report", component: EditReports },
  { route: "report-detail", component: ReportDetail },
  //centers
  { route: "center-detail", component: CenterDetail },
  { route: "edit-center", component: EditCenter },
  { route: "add-center", component: AddCenter },
  { route: "schedule-by-center", component: ScheduleByCenter },

  { route: "courses", component: Courses },
  { route: "course-titles", component: CourseTitle },
  //schedules
  { route: "teacher-by-schedule", component: ScheduleByTeacher },
  { route: "schedules", component: Schedule },
  { route: "add-schedule", component: AddSchedule },
  { route: "edit-schedule", component: EditSchedule },
  { route: "schedule-detail", component: ScehduleDetail },
  //new student
  { route: "new-student-registration", component: NewStudentRegisteration },
  { route: "new-student-form", component: NewStudentForm },
  { route: "new-student-detail", component: RegistrationDetail },
  { route: "new-student-edit", component: NewStudentFormEdit },
  { route: "new-student-forms-edit", component: EditRegisterNewStudent },

  //old student
  { route: "old-student-registration", component: OldStduentRegisteration },
  { route: "old-student-form", component: OldStudentForm },
  { route: "old-student-detail", component: OldStudentRegistrationDetail },
  { route: "old-student-edit", component: OldStudentFormEdit },
  { route: "old-student-forms-edit", component: EditRegisterOldStudent },
  //server student
  { route: "server-registration", component: ServerRegisteration },
  { route: "server-form", component: ServerForm },
  { route: "server-detail", component: ServerRegistrationDetail },
  { route: "edit-server-form", component: EditServerForm },
  //long-course-form
  {
    route: "old-student-long-course-registration",
    component: LongCourseFormRegistration,
  },
  {
    route: "edit-old-student-long-course-registration",
    component: EditLongForm,
  },
  {
    route: "edit-second-old-student-long-course-registration",
    component: EditSecondPage,
  },
  { route: "long-course-registration", component: LongCourseForm },
  { route: "long-course-registration-form", component: SecondPage },
  { route: "long-form-detail", component: LongFormDetail },
  //assitstant-teacher
  { route: "assistant-teacher", component: AssistantTeacher },
  { route: "regional-teacher", component: RegionalTeacher },

  //referrals
  { route: "referrals", component: Referrals },
  //teachers
  {
    route: "teachers-duty",
    component: TeacherView,
  },
  { route: "add-teachers", component: AddTeachers },
  { route: "edit-teachers", component: EditTeachers },
  { route: "teachers", component: Teachers },
  //all student data
  { route: "all-student-data", component: AllStudentData },
  { route: "add-all-student-data", component: AddAllStudentData },
  { route: "edit-all-student-data", component: EditData },
  { route: "student-data-detail", component: StudentDetail },

  { route: "registration-complete", component: FormComplete },
  { route: "registration-form-new", component: RegisterNewStudent },
  { route: "registration-form-old", component: RegisterOldStudent },
  { route: "dhamma-worker-timetable", component: ServerTimetable },
  { route: "registration-complete", component: FormComplete },
  { route: "users", component: Person },
];
