import React from "react";
import { Image } from "react-bootstrap";

interface AppLoaderProps {}

const AppLoader: React.FC<AppLoaderProps> = () => {
  return (
    <div className="d-flex justify-content-center align-items-center position-fixed end-0 bottom-0 w-100 h-100 min-vh-100 min-vw-100">
      <Image src={"images/logo.png"} width={80} className="opacity-50" />
    </div>
  );
};

export default AppLoader;
